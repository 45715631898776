<script setup lang="ts">
import Title from "@/components/Title/Title.vue";
import Avatar from "@/components/Avatar/Avatar.vue";

import { CopyIcon } from "lucide-vue-next";
import { copyText } from "@/utils/useClipboard";

const cupboards = [
  {
    id: 2839,
    position: "-789.1702,45.8797,-953.969",
    grid: "H18",
    is_authed: false,
    inventory: [
      "building.planner:1",
      "building.planner:1",
      "skull.human:4",
      "hammer:1",
      "hammer:1",
    ],
    authed: ["76561198881510972", "76561199024481180", "76561199296760908"],
  },
  {
    id: 3630,
    is_authed: true,
    position: "-428.6543,48.79456,-531.6309",
    grid: "J15",
    inventory: [
      "metal.ore:6750",
      "bone.fragments:65",
      "fat.animal:20",
      "crude.oil:250",
      "scrap:240",
      "metal.refined:10",
      "charcoal:48",
      "cloth:65",
      "metal.fragments:6157",
      "lowgradefuel:35",
      "wood:5391",
      "stones:20531",
      "building.planner:1",
      "hammer:1",
      "hq.metal.ore:20",
      "paper:1",
      "coal:1",
    ],
    authed: ["76561198881510972", "76561199544494554"],
  },
];

const formatImage = (shortName: string) => {
  const shortNameParsed = shortName.split(":")[0];

  return `https://cdn.tirify.com/web/game/rust/items/${shortNameParsed}.png`;
};

const formatQuantity = (shortName: string) => {
  const quantityParsed = shortName.split(":")[1];

  return quantityParsed === "1" ? null : quantityParsed;
};
</script>

<template>
  <div>
    <Title>Шкафы игрока</Title>
    <div class="flex flex-col gap-y-4">
      <div
        class="bg-[#2E2E2E] p-2 rounded-md flex flex-col"
        v-for="(item, index) in cupboards"
        :key="index"
      >
        <div class="flex gap-x-2">
          <img
            src="https://cdn.tirify.com/web/game/rust/items/cupboard.tool.png"
            width="40"
            height="40"
            alt=""
          />
          <div>
            <div class="text-neutral-400 flex gap-x-2 items-center relative">
              <p>Шкаф на квадрате</p>
              <span class="font-medium text-neutral-400">{{ item.grid }}</span>
              <CopyIcon
                class="w-4 cursor-pointer h-4 text-neutral-400 hover:text-neutral-300"
                @click="copyText(item.position, 'Координаты скопированы')"
              />
            </div>
            <p class="text-neutral-500 text-sm">
              Игрок в шкафу {{ item.is_authed ? "не" : "" }} авторизирован
            </p>
          </div>
        </div>

        <p class="text-neutral-300 mt-3">Содержимое</p>
        <div class="flex gap-1 mt-1 flex-wrap">
          <div
            class="w-12 h-12 p-1 bg-[#404040] rounded-lg relative"
            v-for="(resource, index) in item.inventory"
            :key="index + 100"
          >
            <img
              v-if="item"
              :src="formatImage(resource)"
              width="40"
              height="40"
              alt=""
            />

            <p
              class="absolute bottom-0.5 right-1 text-neutral-400 text-xs truncate"
            >
              {{ formatQuantity(resource) }}
            </p>
          </div>
        </div>

        <p class="text-neutral-300 mt-3">Авторизации в шкафу</p>

        <div class="flex gap-2 overflow-x-auto flex-wrap mt-1">
          <div
            v-for="user in item.authed"
            :key="user"
            class="flex gap-2 items-center cursor-pointer bg-[#404040] p-1 rounded-md"
          >
            <Avatar
              avatar="https://i.yapx.ru/X4gVq.png"
              status="не в сети"
              status-color="bg-yellow-500"
            />
            <div class="user_info">
              <div
                class="user_name text-sm font-medium leading-5 text-neutral-400 truncate"
              >
                Scare
              </div>
              <div
                class="player_status text-xs font-medium leading-4 text-neutral-500"
              >
                {{ user }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
