<template>
  <div
    ref="menuRef"
    data-context-menu="true"
    id="context-menu"
    class="absolute bg-neutral-800 rounded-md shadow-2xl w-fit py-2 text-neutral-300 z-[9999]"
    :style="{ top: `${y}px`, left: `${x}px` }"
  >
    <ul>
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        @click="
          item.action();
          onClose();
        "
        :class="[
          'block px-4 py-2 cursor-pointer',
          item.disabled
            ? 'text-neutral-600 pointer-events-none'
            : 'hover:bg-neutral-700',
        ]"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const menuRef = ref(null);

// Определяем пропсы компонента контекстного меню
const props = defineProps<{
  x: number; // Координаты меню
  y: number;
  menuItems: Array<{
    text: string; // Текст элемента меню
    action: () => void; // Функция для обработки клика
    disabled?: boolean; // Флаг доступности
  }>;
  onClose: () => void; // Функция для закрытия меню
}>();

onClickOutside(menuRef, () => props.onClose());
const handleResize = () => {
  props.onClose();
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>
