<template>
  <div class="select fixed bottom-0 left-0 w-full">
    <div class="relative">
      <div
        class="select_btn cursor-pointer relative w-full bg-neutral-800 border-t border-t-neutral-700 py-2.5 px-5"
        @click="isOpen = !isOpen"
      >
        <div class="flex items-center gap-2.5">
          <img :src="selected.avatar" alt="" class="h-10 w-10 rounded-full" />
          <div class="w-full">
            <span
              class="block truncate text-left text-sm font-medium text-neutral-400"
              >{{ selected.name }}</span
            >
            <span class="block text-left text-xs font-medium text-neutral-600"
              >vk.com/trprust</span
            >
          </div>
        </div>
        <span
          class="pointer-events-none absolute transition-colors inset-y-0 right-0 ml-3 flex items-center pr-5"
          :class="isOpen ? 'text-neutral-200' : 'text-neutral-400'"
        >
          <ChevronsUpDown />
        </span>
      </div>

      <div>
        <div
          class="select_menu absolute z-10 w-full rounded-lg bg-neutral-900 p-1 duration-100"
          :class="isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'"
        >
          <div
            class="users_list overflow-auto mb-0.5 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:bg-neutral-700 [&::-webkit-scrollbar-thumb]:bg-neutral-500"
          >
            <ul is="template" v-for="person in people" :key="person.id">
              <li
                :class="[
                  selected.id === person.id
                    ? 'bg-neutral-800 text-white'
                    : 'text-gray-900 ',
                  'list_item flex items-center gap-x-2.5 relative cursor-default rounded-md select-none duration-200 hover:bg-neutral-800 mb-0.5',
                ]"
                @click="
                  selected = person;
                  isOpen = false;
                "
              >
                <div class="list_type">
                  <GripVertical class="text-neutral-500" />
                </div>
                <div class="flex items-center gap-x-2.5">
                  <img
                    :src="person.avatar"
                    alt=""
                    class="h-9 w-9 rounded-full"
                  />
                  <div class="user_info">
                    <span
                      class="user_name truncate-ellipsis block text-sm text-neutral-300 leading-3 font-medium max-w-[78px]"
                      >{{ person.name }}</span
                    >
                    <span
                      class="user_vk text-xs text-neutral-600 leading-2 font-medium"
                      >vk.com/rust</span
                    >
                  </div>
                </div>
                <span
                  v-if="selected.id === person.id"
                  :class="[
                    selected.id === person.id
                      ? 'text-white'
                      : 'text-indigo-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <img src="@/assets/img/icons/selected.svg" alt="" />
                </span>
              </li>
            </ul>
          </div>
          <div class="add_server py-1 border-y border-neutral-800">
            <button
              class="add_server_btn w-full flex items-center rounded-md gap-x-3 py-2.5 px-4 duration-200 hover:bg-neutral-800"
            >
              <img src="@/assets/img/icons/add_server.svg" alt="" />
              <span class="text-sm text-neutral-300 font-medium leading-5"
                >Добавить сервер</span
              >
            </button>
          </div>
          <div class="select_sign_in flex items-center py-1 px-4">
            <button
              class="sign_in_btn py-2.5 text-sm text-neutral-300 font-medium leading-5 duration-200 hover:text-neutral-500"
            >
              Выход
            </button>
            <span
              class="email text-xs text-neutral-500 leading-3 font-medium truncate"
              >vita.vwvw@gmail.com</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import avatar from "@/assets/img/avatar.svg";
import { ref } from "vue";
import { GripVertical, ChevronsUpDown } from "lucide-vue-next";

const people = [
  {
    id: 1,
    name: "Trinity Rust",
    avatar: avatar,
  },
  {
    id: 2,
    name: "Wade Cooper",
    avatar: avatar,
  },
];

const selected = ref(people[0]);
const isOpen = ref(false);
</script>

<style lang="scss" scoped>
.select {
  .select_btn {
    span {
      letter-spacing: 0.01em;
      line-height: 20px;
    }
  }

  .select_menu {
    width: calc(100% - 40px);
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);

    .users_list {
      max-height: 132px;

      .list_item {
        padding: 11px 16px;
        cursor: pointer;

        .user_info {
          .user_name,
          .user_vk {
            letter-spacing: 0.01em;
          }
        }
      }
    }

    .select_sign_in {
      letter-spacing: 0.01em;
      gap: 27px;
    }
  }

  .truncate-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
