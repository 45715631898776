<script setup lang="ts">
import { useSocket } from "./useSocket";
import { Clock, EyeOff, Users, BedSingle } from "lucide-vue-next";

const serverKey = ["afdd2a671431b876e72470ba641a629a"];
const {
  fpsAvg,
  fpsMin,
  onlinePlayers,
  serverTime,
  sleepingPlayers,
  connectingPlayers,
} = useSocket(serverKey);
</script>

<template>
  <div
    class="w-full h-8 bg-neutral-900 text-neutral-400 flex justify-between items-center px-5"
    v-if="fpsAvg"
  >
    <div class="flex w-full gap-2 sm:gap-4 items-center">
      <div class="gap-2 items-center hidden sm:flex" v-tippy="'Игровое время'">
        <Clock class="h-5 w-5" />
        <span>{{ serverTime }}</span>
      </div>
      <div class="flex gap-2 items-center" v-tippy="'Текущий онлайн'">
        <Users class="h-5 w-5" />
        <span
          >{{ onlinePlayers }}
          {{ connectingPlayers ? `+(${connectingPlayers})` : "" }}</span
        >
      </div>
      <div class="flex gap-2 items-center" v-tippy="'Спящих игроков'">
        <BedSingle class="h-5 w-5" />
        <span>{{ sleepingPlayers }}</span>
      </div>
      <div
        class="flex gap-2 items-center"
        v-tippy="'Средний и минимальный FPS'"
      >
        FPS <span>{{ fpsAvg }} ({{ fpsMin }})</span>
      </div>
    </div>
    <EyeOff class="w-5 h-5 cursor-pointer" v-tippy="'Скрыть блок'" />
  </div>
</template>

<style scoped lang="scss"></style>
