<template>
  <ModalWrapper
    :modal-id="modalId"
    :is-open="isOpen"
    :is-animating="isAnimating"
    @onClose="close"
  >
    <div class="fixed left-[50%] translate-x-[-50%] top-[30%] w-4/5 sm:w-auto">
      <div
        :class="
          search.trim()
            ? 'rounded-t-md border-b border-neutral-700'
            : 'rounded-md'
        "
        class="px-4 z-10 bg-neutral-800 flex items-center space-x-2 w-full sm:w-96"
      >
        <Search class="w-4 h-4 text-neutral-400" />
        <input
          v-model="search"
          type="tel"
          ref="inputRef"
          id="input-label"
          :class="search.trim() ? 'rounded-t-md' : 'rounded-md'"
          class="py-3 outline-0 bg-neutral-800 block w-full text-sm border-transparent placeholder-neutral-400 text-neutral-400"
          placeholder="7656115923952395"
          autofocus
        />
      </div>
      <!-- Блок для результатов -->
      <div
        v-if="search.trim()"
        class="bg-neutral-800 overflow-y-auto h-96 rounded-b-md p-2 space-y-2"
      >
        <template v-if="!isLoading">
          <div class="flex flex-col space-y-2 px-2 mt-2">
            <Skeleton height="60" :key="index" v-for="index in 8" />
          </div>
        </template>
        <template v-else>
          <div
            class="flex space-x-2 cursor-pointer items-center px-3 py-2 rounded-md hover:bg-neutral-700 bg-[#2E2E2E]"
            v-for="i in 9"
            :key="i"
            @click="openNewModal"
          >
            <img
              class="w-10 h-10"
              src="@/assets/img/avatar.png"
              alt="Аватарка"
            />
            <div class="flex flex-col">
              <p class="text-neutral-300">MaYoR :D</p>
              <!-- Используем v-html для вставки HTML с подсветкой -->
              <p
                class="text-neutral-400 text-sm"
                v-html="highlightSteamId('7656115923952395')"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { showConfirmModal } from "@/utils/useModal";
import { Search } from "lucide-vue-next";
import ModalWrapper from "@/components/Modals/ModalWrapper.vue";
import Skeleton from "@/components/Skeleton/Skeleton.vue";

const search = ref("");
const inputRef = ref(null);

const isLoading = ref(true);

const openNewModal = () => {
  showConfirmModal({
    text: "Тест",
    confirmButtonText: "Подтверждаю",
    title: "Подтвердите действие",
    onConfirm: () => {
      console.log("onConfirm");
    },
  });
};

// Функция для подсветки и подчеркивания
const highlightSteamId = (steamId: string) => {
  const searchTerm = search.value; // Часть Steam ID для подсветки
  const regex = new RegExp(`(${searchTerm})`, "gi");
  return steamId.replace(
    regex,
    `<span class="underline-offset-2 underline font-semibold text-blue-500">$1</span>`
  );
};

// Определяем пропсы
interface ModalProps {
  title: string;
  confirmButtonText: string;
  text: string;
  modalId: string;
  onConfirm?: () => void;
  onClientClose?: () => void;
  onClose: () => void;
}

const props = defineProps<ModalProps>();
const isOpen = ref(false);
const isAnimating = ref(false);

const openModal = () => {
  isOpen.value = true;
  requestAnimationFrame(() => {
    isAnimating.value = true;
  });
};

const close = () => {
  isAnimating.value = false;
  if (props.onClientClose) {
    props.onClientClose();
  }

  setTimeout(() => {
    props.onClose();
  }, 200);
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "Escape" && isOpen.value) {
    close();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeyPress);
  if (inputRef.value) {
    inputRef.value.focus();
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeyPress);
});

defineExpose({ openModal, close });
</script>
