import { ref, onMounted, onUnmounted } from "vue";

// Типы данных, которые будут получены по WebSocket
interface WebSocketMessage {
  fps_avg: number;
  fps_min: number;
  online_players: number;
  server_time: string;
  sleeping_players: number;
  connecting_players: number;
}

// Хук для работы с WebSocket
export const useSocket = (serverKey: string[]) => {
  const fpsAvg = ref<number>(0);
  const fpsMin = ref<number>(0);
  const onlinePlayers = ref<number>(0);
  const serverTime = ref<string>("");
  const sleepingPlayers = ref<number>(0);
  const connectingPlayers = ref<number>(0);
  let socket: WebSocket | null = null;

  const sendMessage = (message: { semi_subscribe: string[] }) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open");
    }
  };

  const connect = () => {
    socket = new WebSocket(
      "wss://s01-server-stats-gateway.tirify.com/api/v1/user/websocket"
    );

    socket.onopen = () => {
      console.log("Connected to WebSocket");
      sendMessage({ semi_subscribe: serverKey });
    };

    socket.onmessage = (event: MessageEvent) => {
      const message: WebSocketMessage = JSON.parse(event.data);
      fpsAvg.value = message.fps_avg;
      fpsMin.value = message.fps_min;
      onlinePlayers.value = message.online_players;
      serverTime.value = message.server_time;
      sleepingPlayers.value = message.sleeping_players;
      connectingPlayers.value = message.connecting_players;
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };
  };

  const closeConnection = () => {
    if (socket) {
      socket.close();
    }
  };

  onMounted(() => {
    connect();
  });

  onUnmounted(() => {
    closeConnection();
  });

  return {
    fpsAvg,
    fpsMin,
    onlinePlayers,
    serverTime,
    sleepingPlayers,
    connectingPlayers,
  };
};
