import { createApp } from "vue";
import ContextMenuComponent from "../components/Modals/ContextMenu.vue"; // Импортируем компонент контекстного меню

// Текущий инстанс и контейнер для контекстного меню
let contextMenuApp: any = null;
let contextMenuInstance: any = null;
let container: HTMLElement | null = null;

// Универсальная функция для отображения контекстного меню
function showContextMenu({
  x, // Координаты
  y,
  menuItems, // Элементы меню
}: {
  x: number;
  y: number;
  menuItems: Array<{ text: string; action: () => void; disabled?: boolean }>;
}) {
  // Закрываем предыдущее контекстное меню, если оно открыто
  if (contextMenuInstance && container) {
    closeContextMenu();
  }

  // Создаём новый контейнер для контекстного меню
  container = document.createElement("div");
  document.body.appendChild(container);

  // Создаём приложение Vue с переданным компонентом контекстного меню и его пропсами
  contextMenuApp = createApp(ContextMenuComponent, {
    x,
    y,
    menuItems,
    onClose: () => {
      closeContextMenu(); // Закрываем меню
    },
  });

  // Монтируем приложение
  contextMenuInstance = contextMenuApp.mount(container);
}

// Функция для закрытия контекстного меню
function closeContextMenu() {
  if (contextMenuInstance && container) {
    contextMenuApp.unmount();
    document.body.removeChild(container);
    contextMenuInstance = null;
    contextMenuApp = null;
    container = null;
  }
}

// Экспортируем функцию для вызова контекстного меню
export const useContextMenu = () => {
  return {
    showContextMenu,
    closeContextMenu,
  };
};
