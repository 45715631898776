import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "auth",
    component: () => import("../views/Authorization/Authorization.vue"),
    meta: {
      layout: "default-layout",
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("../views/Authorization/Registration.vue"),
    meta: {
      layout: "default-layout",
    },
  },
  {
    path: "/players",
    name: "players",
    component: () => import("../views/Moderation/Players.vue"),
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/activities",
    name: "activities",
    component: () => import("../views/Moderation/StrangeActivities.vue"),
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("../views/Stats/ServerStats.vue"),
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/online-players",
    name: "onlinePlayers",
    component: () =>
      import("../views/Moderation/OnlinePlayers/OnlinePlayers.vue"),
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/screenshots",
    name: "screenshots",
    component: () => import("../views/Moderation/PlayersScreens.vue"),
    meta: {
      layout: "main-layout",
    },
  },
  {
    path: "/audit-log",
    name: "audit-log",
    component: () => import("../views/Managment/AuditLog.vue"),
    meta: {
      layout: "main-layout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100);
  }
});
export default router;
