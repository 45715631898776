<template>
  <ModalWrapper
    :modal-id="modalId"
    :is-open="isOpen"
    :is-animating="isAnimating"
    @onClose="close"
  >
    <img :src="props.img" alt="" class="cursor-zoom-out" @click="close" />
  </ModalWrapper>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import ModalWrapper from "@/components/Modals/ModalWrapper.vue";

interface ModalProps {
  img: string;
  modalId: string;
  onClose: () => void;
}

const props = defineProps<ModalProps>();
const isOpen = ref(false);
const isAnimating = ref(false);

const openModal = () => {
  isOpen.value = true;
  requestAnimationFrame(() => {
    isAnimating.value = true;
  });
};

const close = () => {
  isAnimating.value = false;
  setTimeout(() => {
    isOpen.value = false;
    props.onClose();
  }, 200);
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "Escape" && isOpen.value) {
    close();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeyPress);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeyPress);
});

defineExpose({ openModal, close });
</script>
