import { useClipboard } from "@vueuse/core";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CopyIcon from "@/components/Icons/CopyIcon.vue";

export function copyText(textToCopy: string, successText?: string) {
  const { copy } = useClipboard();

  copy(textToCopy).then(() => {
    toast.success(successText || "Успешно скопировано!", {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
      // @ts-ignore
      icon: CopyIcon,
      position: "bottom-right",
    });
  });
}
