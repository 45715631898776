import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "preline/preline";
import "tailwindcss/tailwind.css";
import "vue3-toastify/dist/index.css";
import { createPinia } from "pinia";

const pinia = createPinia();

import VueTippy from "vue-tippy";
import Vue3Toastify from "vue3-toastify";

createApp(App)
  .use(pinia)
  .use(router)
  .use(Vue3Toastify)
  .use(VueTippy, {
    directive: "tippy",
    defaultProps: {
      delay: [100, 0],
      duration: [300, 0],
    },
  })
  .mount("#app");
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((registration) => {
      console.log(
        "Service Worker зарегистрирован с областью:",
        registration.scope
      );
    })
    .catch((error) => {
      console.log("Ошибка регистрации Service Worker:", error);
    });
}
