<template>
  <ModalWrapper
    :modal-id="modalId"
    :is-open="isOpen"
    :is-animating="isAnimating"
    @onClose="close"
  >
    <div
      class="w-full min-w-96 flex flex-col border shadow-sm rounded-xl pointer-events-auto bg-neutral-800 border-neutral-700 shadow-neutral-700/70"
    >
      <div
        class="flex justify-between items-center py-3 px-4 border-b border-neutral-700"
      >
        <h3 id="generalModal" class="font-medium text-neutral-100">
          {{ title }}
        </h3>
        <button
          type="button"
          class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent focus:outline-none bg-neutral-700 hover:bg-neutral-600 text-neutral-400"
          aria-label="Close"
          @click="close"
        >
          <svg
            class="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6L6 18"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div class="p-4 overflow-y-auto text-neutral-200">
        {{ text }}
      </div>
      <div
        class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-neutral-700"
      >
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border shadow-sm bg-neutral-800 border-neutral-700 text-neutral-50 hover:bg-neutral-700"
          @click="onClose"
        >
          Закрыть
        </button>
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 hover:bg-blue-700 text-white focus:outline-none"
          @click="confirm"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import ModalWrapper from "@/components/Modals/ModalWrapper.vue";

// Определяем пропсы
interface ModalProps {
  title: string;
  confirmButtonText: string;
  text: string;
  modalId: string;
  onConfirm?: () => void;
  onClientClose?: () => void;
  onClose: () => void;
}

const props = defineProps<ModalProps>();
const isOpen = ref(false);
const isAnimating = ref(false);

const openModal = () => {
  isOpen.value = true;
  requestAnimationFrame(() => {
    isAnimating.value = true;
  });
};

const close = () => {
  isAnimating.value = false;
  if (props.onClientClose) {
    props.onClientClose();
  }

  setTimeout(() => {
    props.onClose();
  }, 200);
};

// Действие при подтверждении
const confirm = () => {
  console.log("props.onConfirm", props.onConfirm);
  if (props.onConfirm) {
    props.onConfirm();
  }
  props.onClose();
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "Escape" && isOpen.value) {
    close();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeyPress);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeyPress);
});

defineExpose({ openModal, close });
</script>
