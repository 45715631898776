<template>
  <div
    class="bg-neutral-700 animate-pulse"
    :class="[roundedClass ? roundedClass : 'rounded-md']"
    :style="{
      height: computedHeight,
      width: computedWidth,
      minWidth: computedWidth,
    }"
  ></div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface SkeletonProps {
  height: string | number;
  width?: string | number;
  roundedClass?: string;
}

const props = withDefaults(defineProps<SkeletonProps>(), {
  height: "auto",
  width: "auto",
});
const addPxIfNeeded = (value: string | number): string => {
  if (typeof value === "number") {
    return `${value}px`;
  }
  return /\d$/.test(value) ? `${value}px` : value;
};

// Вычисление значения высоты с проверкой
const computedHeight = computed(() => addPxIfNeeded(props.height));

// Вычисление значения ширины с проверкой
const computedWidth = computed(() => addPxIfNeeded(props.width));
</script>
