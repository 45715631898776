<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { onMounted, onUnmounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    contentClass?: string;
    isAnimating?: boolean;
    roundedMobile?: boolean;
    modalId: string;
  }>(),
  {
    roundedMobile: true,
  }
);
const emit = defineEmits<{
  (e: "onClose"): void;
}>();

const refContent = ref(null);

const lockScroll = () => {
  document.body.style.overflow = "hidden";
};

const unlockScroll = () => {
  document.body.style.overflow = "";
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "Escape" && props.isOpen) {
    emit("onClose");
  }
};

onClickOutside(refContent, () => {
  const currentModal = document.getElementById(props.modalId);
  const contextMenu = document.getElementById("context-menu");

  if (currentModal) {
    if (currentModal.classList.contains("hidden")) {
      return;
    }

    if (!contextMenu) {
      emit("onClose");
    }
  }
});

onMounted(() => {
  window.addEventListener("keydown", handleKeyPress);
  lockScroll();
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyPress);
  unlockScroll();
});

watch(
  () => props.isOpen,
  (newValue) => {
    if (!newValue) {
      unlockScroll();
      window.removeEventListener("keydown", handleKeyPress);
    } else {
      lockScroll();
      window.addEventListener("keydown", handleKeyPress);
    }
  }
);
</script>

<template>
  <div
    v-if="isOpen"
    :data-modal-id="modalId"
    class="modal bg-neutral-900 bg-opacity-80 size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-auto"
    role="dialog"
    tabindex="-1"
    aria-labelledby="modelWrapper"
  >
    <div
      :class="[
        'hs-overlay-animation-target sm:w-fit m-0 sm:m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center transition-all duration-200',
        isAnimating ? 'scale-100 opacity-100' : 'scale-95 opacity-0',
      ]"
      @click.stop
    >
      <div
        ref="refContent"
        class="w-full overflow-hidden shadow-sm pointer-events-auto shadow-neutral-700/70"
        :class="[
          roundedMobile ? 'rounded-xl' : 'rounded-none sm:rounded-xl',
          contentClass,
        ]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.size-full {
  width: 100%;
  height: 100%;
}

.scale-100 {
  transform: scale(1);
}

.scale-95 {
  transform: scale(0.95);
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}
</style>
