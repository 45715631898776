<template>
  <component :is="layout" id="app">
    <router-view :class="isMainLayout && 'sm:p-5 px-2 py-5'" />
  </component>
</template>
<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";
import StatsBanner from "@/components/StatsBanner/StatsBanner.vue";

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100);
});

// Получаем текущий маршрут
const route = useRoute();

// Определяем используемый макет в зависимости от метаданных маршрута
const layout = computed(() => {
  const layoutType = route.meta.layout || "default-layout";
  return layoutType === "main-layout" ? AuthorizedLayout : DefaultLayout;
});

const isMainLayout = computed(() => {
  const layoutType = route.meta.layout || "default-layout";
  return layoutType === "main-layout";
});
</script>
<style lang="scss">
:root {
  --toastify-color-light: #1c1917 !important;
  --toastify-text-color-light: #a3a3a3 !important;
}
.tippy-box {
  @apply bg-neutral-900 border-neutral-700 text-neutral-400 rounded-md px-1 py-0.5 text-sm;
}

.tippy-arrow {
  @apply text-neutral-400;
}

/* Скрываем скролл-бар, когда он не используется */
@media (min-width: 1200px) {
  html {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.55) transparent; /* Цвет для Firefox */
    border-radius: 10px;
  }

  /* Стилизация скролл-бара для WebKit-браузеров (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 8px; /* Ширина скролл-бара */
    height: 8px; /* Высота горизонтального скролл-бара */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Фон трека скролл-бара */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2); /* Цвет ползунка скролл-бара */
    border-radius: 10px; /* Скругленные углы */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(
      255,
      255,
      255,
      0.3
    ); /* Более тёмный цвет при наведении */
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(
      0,
      0,
      0,
      0.4
    ); /* Ещё темнее при активном взаимодействии */
  }
}
</style>
