<script setup lang="ts"></script>

<template>
  <div>
    <p class="text-xl text-neutral-300 mb-4">Об игроке</p>

    <div class="space-y-2 text-sm">
      <!-- Первая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Играл на</div>
        <div class="w-1/2 text-neutral-300">Alkad</div>
      </div>

      <!-- Вторая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Впервые замечен</div>
        <div class="w-1/2 text-neutral-300">23.08.2024 в 21:53</div>
      </div>

      <!-- Третья строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Страна, город</div>
        <div class="w-1/2 text-neutral-300">Russia, Sochi</div>
      </div>

      <!-- Четвёртая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Steam ID</div>
        <div class="w-1/2 text-neutral-300">76561199296760908</div>
      </div>

      <!-- Пятая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">IP адрес</div>
        <div class="w-1/2 text-neutral-300">188.0.175.153</div>
      </div>

      <!-- Шестая строка -->
      <div class="flex pb-2">
        <div class="w-2/3 text-neutral-500">Провайдер</div>
        <div class="w-1/2 text-neutral-300">CJSC Vainah Telecom</div>
      </div>
    </div>

    <!-- Секция Steam -->
    <p class="text-xl text-neutral-300 mt-6 mb-4">Информация из Steam</p>

    <div class="space-y-2 text-sm">
      <!-- Седьмая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Приватность</div>
        <div class="w-1/2 text-neutral-300">Профиль скрыт</div>
      </div>

      <!-- Восьмая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Gamebans</div>
        <div class="w-1/2 text-neutral-300">Банов нет</div>
      </div>

      <!-- Девятая строка -->
      <div class="flex border-b border-neutral-800 pb-2">
        <div class="w-2/3 text-neutral-500">Часов в RUST</div>
        <div class="w-1/2 text-neutral-300">Неизвестно</div>
      </div>

      <!-- Десятая строка -->
      <div class="flex pb-2">
        <div class="w-2/3 text-neutral-500">Аккаунт создан</div>
        <div class="w-1/2 text-neutral-300">Неизвестно</div>
      </div>
    </div>
  </div>
</template>
