<template>
  <div class="flex items-center gap-2.5">
    <div class="avatar relative rounded-full" :class="backgroundColor">
      <img
        :src="avatar"
        alt="аватарка игрока"
        class="rounded-full"
        :class="avatarSizeClass"
      />
      <div
        v-if="computedStatusColor"
        class="user_status rounded-full p-0.5 absolute -top-px -right-px z-2"
      >
        <span
          class="block rounded-full w-2 h-2 ring-2 ring-neutral-800"
          :class="computedStatusColor"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from "vue";

interface PlayerProps {
  avatar: string;
  status?: "away" | "offline" | "online";
  statusColor?: string;
  large?: boolean;
  small?: boolean;
  backgroundColor?: string;
}

const props = defineProps<PlayerProps>();

const avatarSizeClass = computed(() => {
  if (props.small) {
    return "w-7 h-7";
  } else if (props.large) {
    return "w-12 h-12";
  } else {
    return "w-9 h-9";
  }
});

const computedStatusColor = computed(() => {
  // Приоритет у явно переданного statusColor, если есть
  if (props.statusColor) {
    return props.statusColor;
  }

  // Логика для статусов
  switch (props.status) {
    case "away":
      return "bg-yellow-500";
    case "offline":
      return "bg-neutral-500";
    case "online":
      return "bg-green-500";
    default:
      return "";
  }
});
</script>
