<template>
  <ModalWrapper
    content-class="w-[90%] mx-auto sm:w-full"
    :is-open="isOpen"
    :is-animating="isAnimating"
    @onClose="close"
  >
    <div
      class="w-full sm:w-96 flex flex-col pointer-events-auto bg-[#1E1E1E] rounded-md"
    >
      <div
        class="flex justify-between items-center py-3 px-4 border-b border-neutral-700"
      >
        <h3 id="generalModal" class="font-medium text-neutral-100">
          Информация об IP {{ ipData ? ipData.query : ipAddress }}
        </h3>
        <button
          type="button"
          class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent focus:outline-none bg-neutral-700 hover:bg-neutral-600 text-neutral-400"
          aria-label="Close"
          @click="close"
        >
          <svg
            class="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6L6 18"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div class="space-y-2 text-sm px-4 py-3 h-[175px]">
        <div v-if="isLoading || !ipData">
          <!-- Здесь будет ваш скелетон -->
          <div class="flex flex-col gap-y-2">
            <Skeleton height="30" />
            <Skeleton height="30" />
            <Skeleton height="30" />
            <Skeleton height="30" />
          </div>
        </div>
        <div v-else>
          <div class="flex border-b border-neutral-800 items-center py-2">
            <div class="w-2/3 text-neutral-500">Страна</div>
            <div class="w-1/2 text-neutral-300 flex items-center gap-x-1.5">
              <img
                :src="'https://flagcdn.com/' + ipData.countryCode + '.svg'"
                width="30"
                height="12"
                class="rounded-sm"
                alt="Флаг страны"
              />
              <span>{{ ipData.country }}</span>
            </div>
          </div>

          <div class="flex border-b border-neutral-800 py-2 items-center">
            <div class="w-2/3 text-neutral-500">Город</div>
            <div class="w-1/2 text-neutral-300">{{ ipData.city }}</div>
          </div>

          <div class="flex py-2 items-center border-b border-neutral-800">
            <div class="w-2/3 text-neutral-500">Провайдер</div>
            <div class="w-1/2 text-neutral-300 truncate">{{ ipData.isp }}</div>
          </div>
          <div class="flex py-2 items-center">
            <div class="w-2/3 text-neutral-500">VPN/Прокси</div>
            <div
              class="w-1/2 text-neutral-300"
              :class="{ 'text-amber-500 font-semibold': ipData.proxy }"
            >
              {{ ipData.proxy ? "Да" : "Нет" }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-neutral-700"
        :class="{ 'pointer-events-none && opacity-70': !ipData }"
      >
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg shadow-sm bg-neutral-800 text-neutral-200 hover:bg-neutral-700"
          @click="close"
        >
          Закрыть
        </button>
        <a
          target="_blank"
          :href="'https://proxycheck.io/threats/' + ipData?.query"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg bg-neutral-800 text-neutral-200 hover:bg-neutral-700"
        >
          Подробнее
        </a>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import axios from "axios";
import ModalWrapper from "@/components/Modals/ModalWrapper.vue";
import Skeleton from "@/components/Skeleton/Skeleton.vue";

// Определяем пропсы

interface IpDataInterface {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: string;
  lat: number;
  lon: number;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;
  proxy: boolean;
}
interface ModalProps {
  data?: IpDataInterface;
  ipAddress?: string;
  onClose: () => void;
}

const props = defineProps<ModalProps>();
const isOpen = ref(false);
const isAnimating = ref(false);
const isLoading = ref(false);
const ipData = ref<IpDataInterface | null>(null);

const openModal = () => {
  isOpen.value = true;
  requestAnimationFrame(() => {
    isAnimating.value = true;
  });
};

const close = () => {
  isAnimating.value = false;
  setTimeout(() => {
    isOpen.value = false;
    props.onClose();
  }, 200);
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "Escape" && isOpen.value) {
    close();
  }
};

onMounted(() => {
  isLoading.value = true;

  window.addEventListener("keydown", handleKeyPress);

  if (props.data) {
    ipData.value = props.data;
  } else if (props.ipAddress) {
    axios
      .get(`/api/v1/ip/info?ip=${props.ipAddress}`)
      .then((response) => {
        response.data.countryCode = response.data.countryCode.toLowerCase();
        console.log("response", response.data);
        ipData.value = response.data;
      })
      .catch((error) => {
        console.error("Ошибка при получении данных IP:", error);
        close();
      })
      .finally(() => {
        isLoading.value = false;
      });
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeyPress);
});

defineExpose({ openModal, close });
</script>
