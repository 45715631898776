<script setup lang="ts">
import Title from "@/components/Title/Title.vue";
import Avatar from "@/components/Avatar/Avatar.vue";
import { showImagePreview } from "@/utils/useModal";
import { Copy } from "lucide-vue-next";
import { copyText } from "@/utils/useClipboard";

const openImagePreview = () => {
  showImagePreview({ img: "https://i.postimg.cc/MTgLZTYT/image.png" });
};
</script>

<template>
  <div>
    <Title>Скриншоты античита</Title>
    <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
      <div
        class="rounded-md bg-neutral-800 flex flex-col"
        v-for="index in 12"
        :key="index"
      >
        <img
          src="https://i.postimg.cc/MTgLZTYT/image.png"
          alt=""
          class="object-fill rounded-t-md cursor-zoom-in h-[150px]"
          @click="openImagePreview"
        />
        <div class="p-4 rounded-b-md gap-y-1 flex flex-col">
          <div class="flex justify-between w-full text-sm">
            <p class="text-neutral-400">Дата</p>
            <p class="text-neutral-300">20:15</p>
          </div>
          <div class="flex justify-between w-full text-neutral-400 text-sm">
            <p class="text-neutral-400">Сервер</p>
            <p class="text-neutral-300">Trinity Rust</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
