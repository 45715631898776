<template>
  <ModalWrapper
    :modal-id="props.modalId"
    :rounded-mobile="false"
    :is-open="isOpen"
    :is-animating="isAnimating"
    @onClose="close"
  >
    <div class="flex w-max sm:w-auto flex-nowrap">
      <div
        class="w-screen h-screen sm:h-auto sm:w-[260px] bg-neutral-800 relative"
      >
        <X
          class="w-7 h-7 sm:hidden text-neutral-400 absolute top-3 right-3 z-50"
          :class="isOpenNavigateMobile && 'hidden'"
          @click="close"
        />
        <div class="relative overflow-hidden">
          <div class="w-full h-full bg-gradient absolute z-[5]"></div>

          <div class="absolute inset-0 blur-[14px]">
            <img
              src="https://i.postimg.cc/SR6dHfkx/image.png"
              class="w-full h-full object-cover select-none"
              alt=""
            />
          </div>

          <div class="flex flex-col justify-between h-full mt-5 sm:mt-0">
            <div class="flex gap-4 p-6 relative z-10">
              <Avatar
                avatar="https://i.postimg.cc/SR6dHfkx/image.png"
                status-color="bg-yellow-500"
              />
              <div class="leading-5">
                <p class="text-neutral-300">mayorik</p>
                <p class="text-neutral-500">не в сети</p>
              </div>
            </div>
            <div class="px-6 flex justify-between z-[5] gap-2">
              <!-- Кнопка Steam -->
              <button
                class="flex-1 text-center bg-neutral-700 hover:bg-neutral-600 transition-all text-neutral-300 py-1.5 rounded-md flex justify-center items-center"
              >
                <img
                  src="@/assets/img/icons/steam.svg"
                  class="w-5 h-5"
                  alt=""
                />
              </button>

              <button
                @click="openContextMenu"
                class="w-full flex-1 text-center bg-neutral-700 hover:bg-neutral-600 transition-all text-neutral-300 py-1.5 rounded-md flex justify-center items-center"
              >
                <EllipsisVertical class="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        <nav class="p-6 hidden sm:flex flex-col">
          <ul>
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              :class="{ 'bg-neutral-700': activeTabIndex === index }"
              class="flex cursor-pointer items-center py-2 px-3 mb-1.5 text-start text-sm text-neutral-300 rounded-lg gap-2 duration-200 hover:bg-neutral-700"
              @click="() => setActiveTab(index)"
            >
              <component :is="tab.icon" class="w-4 h-4" />
              <span>{{ tab.name }}</span>
            </li>
          </ul>
        </nav>
        <nav class="p-6 flex flex-col sm:hidden">
          <ul>
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              class="flex cursor-pointer justify-between items-center py-2 px-3 mb-1.5 text-start text-sm text-neutral-300 rounded-lg gap-2 duration-200 bg-neutral-700"
              @click="() => setActiveTabMobile(index)"
            >
              <div class="flex gap-2 items-center">
                <component :is="tab.icon" class="w-4 h-4" />
                <span>{{ tab.name }}</span>
              </div>
              <ChevronRight class="w-5 h-5" />
            </li>
          </ul>
        </nav>
      </div>
      <div
        class="p-6 bg-[#1E1E1E] overflow-y-auto w-screen sm:w-[600px] h-screen sm:h-[505px] relative z-10 transition-transform duration-300"
        :class="isOpenNavigateMobile && 'translate-x-[-100%]'"
      >
        <ChevronLeft
          class="sm:hidden w-7 h-7 mb-4 text-neutral-400"
          @click="isOpenNavigateMobile = false"
        />

        <component :is="tabs[activeTabIndex].component" />
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Avatar from "@/components/Avatar/Avatar.vue";
import { useContextMenu } from "@/utils/useContextMenu";
import { ChevronLeft, X } from "lucide-vue-next";

const { showContextMenu } = useContextMenu();

import {
  LayoutDashboard,
  Users,
  CircleAlert,
  PanelBottomClose,
  History,
  Box,
  ChevronRight,
  Image,
  EllipsisVertical,
} from "lucide-vue-next";
import ModalWrapper from "@/components/Modals/ModalWrapper.vue";
import UserInfo from "@/components/UserModalTabs/UserInfo.vue";
import UserCupboards from "@/components/UserModalTabs/UserCupboards.vue";
import UserStash from "@/components/UserModalTabs/UserStash.vue";
import UserScreenshots from "@/components/UserModalTabs/UserScreenshots.vue";
import UserEvents from "@/components/UserModalTabs/UserEvents.vue";
import UserFriends from "@/components/UserModalTabs/UserFriends.vue";

// Определяем пропсы
interface ModalProps {
  modalId: string;
  onClose: () => void;
}

const isOpenNavigateMobile = ref(false);
const tabs = [
  { name: "Обзор", icon: LayoutDashboard, component: UserInfo },
  { name: "Команда", icon: Users, component: UserFriends },
  { name: "Лог игрока", icon: CircleAlert, component: UserEvents },
  { name: "История", icon: History, component: UserInfo },
  { name: "Шкафы", icon: Box, component: UserCupboards },
  { name: "Тайники", icon: PanelBottomClose, component: UserStash },
  { name: "Скриншоты", icon: Image, component: UserScreenshots },
];

const setActiveTab = (index: number) => {
  activeTabIndex.value = index;
};
const setActiveTabMobile = (index: number) => {
  activeTabIndex.value = index;
  isOpenNavigateMobile.value = true;
};

const openContextMenu = (event: MouseEvent) => {
  const x = event.clientX;
  const y = event.clientY;

  // Определяем элементы меню
  const menuItems = [
    {
      text: "Скопировать",
      action: () => console.log("Скопировать"),
      disabled: false, // Пункт меню доступен
    },
    {
      text: "Показать сообщения",
      action: () => console.log("Показать сообщения"),
      disabled: false, // Пункт меню доступен
    },
    {
      text: "Заблокировать",
      action: () => console.log("Заблокировать пользователя"),
      disabled: true, // Пункт меню недоступен
    },
  ];

  // Показываем контекстное меню
  showContextMenu({ x, y, menuItems });
};
const activeTabIndex = ref(4);
const props = defineProps<ModalProps>();
const isOpen = ref(false);
const isAnimating = ref(false);

const openModal = () => {
  isOpen.value = true;
  requestAnimationFrame(() => {
    isAnimating.value = true;
  });
};

const close = () => {
  isAnimating.value = false;
  setTimeout(() => {
    isOpen.value = false;
    props.onClose();
  }, 200);
};
defineExpose({ openModal, close });
</script>

<style scoped lang="scss">
.bg-gradient {
  background: linear-gradient(
    184deg,
    rgba(38, 38, 38, 0.8) 3.5%,
    rgba(38, 38, 38, 1) 90%,
    #272727 100%
  );
}
</style>
