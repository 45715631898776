import { createApp, App, ComponentPublicInstance } from "vue";
import ConfirmModal from "../components/Modals/ConfirmModal.vue";
import SearchModal from "../components/Modals/SearchModal.vue";
import UserModal from "../components/Modals/UserModal.vue";
import IpInfoModal from "../components/Modals/IpInfoModal.vue";
import ImagePreview from "../components/Modals/ImagePreview.vue";

// Определяем интерфейс для экземпляра модального окна
interface ModalInstance extends ComponentPublicInstance {
  openModal?: () => void; // Не у всех модальных окон есть метод openModal, поэтому он опционален
}

// Обновляем тип ModalInfo
type ModalInfo = {
  modalApp: App;
  modalInstance: ModalInstance;
  container: HTMLElement;
  component: any; // Можем уточнить тип позже, если будет необходимость
  props: Record<string, any>;
};

// Стек для хранения информации о предыдущих модальных окнах
let modalStack: ModalInfo[] = [];
let currentModal: ModalInfo | null = null;

// Универсальная функция для открытия модального окна
function showModal(ModalComponent: any, props: Record<string, any> = {}) {
  const modalId = Date.now().toString(); // Уникальный идентификатор

  if (currentModal) {
    modalStack.push(currentModal);
    currentModal.container.style.display = "none";
    currentModal.container.style.pointerEvents = "none";
    currentModal.container.classList.add("hidden");
  }

  const container = document.createElement("div");
  container.id = modalId;
  document.body.appendChild(container);

  const modalApp = createApp(ModalComponent, {
    ...props,
    modalId,
    onClose: () => {
      closeModal(true);
    },
  });

  const modalInstance = modalApp.mount(container) as ModalInstance;

  if (modalInstance.openModal) {
    modalInstance.openModal();
  }

  currentModal = {
    modalApp,
    modalInstance,
    container,
    component: ModalComponent,
    props,
  };
}

// Универсальная функция для закрытия модального окна
function closeModal(restorePrevious: boolean) {
  if (currentModal) {
    const { modalApp, container } = currentModal;
    modalApp.unmount();
    document.body.removeChild(container);
    currentModal = null;
  }

  if (restorePrevious && modalStack.length > 0) {
    const previousModal = modalStack.pop()!;
    previousModal.container.style.display = "block";
    previousModal.container.style.pointerEvents = "auto";
    previousModal.container.classList.remove("hidden");

    if (previousModal.modalInstance.openModal) {
      previousModal.modalInstance.openModal();
    }
    currentModal = previousModal;
  }
}

// Функции для показа конкретных модальных окон
export const showConfirmModal = (props: {
  title: string;
  confirmButtonText: string;
  text: string;
  onConfirm?: () => void;
  onClientClose?: () => void;
}) => {
  showModal(ConfirmModal, props);
};

export const showSearchModal = (props: {
  onConfirm?: () => void;
  onClientClose?: () => void;
}) => {
  showModal(SearchModal, props);
};

export const showUserModal = (props: {
  onConfirm?: () => void;
  onClientClose?: () => void;
}) => {
  showModal(UserModal, props);
};

export const showImagePreview = (props: { img: string }) => {
  showModal(ImagePreview, props);
};

export const showIpInfoModal = (props: {
  data?: {
    status: string;
    country: string;
    countryCode: string;
    region: string;
    regionName: string;
    city: string;
    zip: string;
    lat: number;
    lon: number;
    timezone: string;
    isp: string;
    org: string;
    as: string;
    query: string;
  };
  ipAddress?: string;
}) => {
  showModal(IpInfoModal, props);
};
